
import { Options } from "vue-class-component";
import NodesMinxins from "../NodesMixins";
import InsertButton from "../../../components/InsertButton/InsertButton.vue";

@Options({
    name: "flowable-nodes-concurrent-node",
    components: { InsertButton },
    props: {
        level: {
            type: Number,
            default: 1,
        },
        //条件数
        size: {
            type: Number,
            default: 0,
        },
    },
    emits: ["selected", "leftMove", "copy", "delNode", "rightMove", "insertNode"],
})
export default class ConcurrentNode extends NodesMinxins {}
